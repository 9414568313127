import store from "@/store/store";
import { FlowsService } from "@/includes/services/FlowService";

import GetUserInfoService from "piramis-base-components/src/shared/logic/getUsersInfo/GetUserInfoService";
import { GetUserInfoRes } from "piramis-base-components/src/shared/logic/getUsersInfo/types";

export default class GetUserInfoServiceImpl extends GetUserInfoService {
    getUsersInfoReq(users: Array<number | string>): Promise<{ data: Array<GetUserInfoRes> }> {
        return FlowsService.getUsersInfo('tg', { board_key: store.state.boardsState.activeBoard!.board, values: users })
    }
}