import { PostActionType } from "@/includes/types/Post.types";
import { PostActionTypes } from "@/components/Post/NewActions/components/ActionTypes/PostActionTypes";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { Exclude, Type } from "class-transformer";
import { snakeCase } from "lodash";

export class PostActionModel {
    type!: PostActionType
}

export abstract class PostActionBuilder extends PostActionModel {
    @Exclude()
    abstract view: VueComponent | undefined

    @Exclude()
    abstract valueView: VueComponent | undefined

    /**
     * material icon
     **/
    @Exclude()
    abstract icon: string

    isDisabled() {
        return false
    }

    /**
     * флаг, чтобы отметить карточку действия красным, как устаревшее. к нему настраивается опциональный ключ с текстом
     * */
    @Exclude()
    isDeprecated = false

    /**
     * флаг, чтобы скрыть карточку в списке действий, но при этом, оставить возможность настраивать его, чтобы отдельные списки
     * не делать
     * */
    @Exclude()
    isVisible = true

    disabledMessageKey() {
        return `post_action_${ snakeCase(this.type) }_disabled`
    }

    deprecatedMessageKey() {
        return `post_action_${ snakeCase(this.type) }_deprecated`
    }

    actionTitleKey() {
        return `post_action_${ snakeCase(this.type) }`
    }

    actionHelpMessageKey() {
        return `post_action_${ snakeCase(this.type) }_help_message`
    }
}
