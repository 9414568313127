import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { PostActionType } from "@/includes/types/Post.types";
import store from "@/store/store";
import { PostMessage } from "../PostMessage";

import { PostType } from "piramis-base-components/src/shared/modules/posting/types";
import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { plainToInstance, Type } from "class-transformer";

export default class SendCommentActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.SendCommentAction;

    @Type(() => PostMessage)
    message: PostMessage = plainToInstance(PostMessage, {
        type: PostType.Post,
        variants: [ {
            'text': '',
            'buttons': [],
            'disable_link_preview': false,
            'attachments': [],
            'send_after': 0,
            'remove_after': 0,
            'protect_content': false,
        } ]
    })

    icon: string = 'add_comment'
    view: VueComponent | undefined = () => import('./SendCommentActionView.vue')
    valueView: VueComponent | undefined = () => import('../components/PostActionMessage/PostActionMessageValuePreview.vue')

    isDisabled() {
        const targetsWithoutLinkedGroup = store.state.targetsWithoutLinkedGroup

        if (targetsWithoutLinkedGroup === null) {
            return true
        }

        if (Array.isArray(targetsWithoutLinkedGroup)) {
            return !!targetsWithoutLinkedGroup.length
        }

        return true
    }

    disabledMessageKey(): string {
        return 'post_action_group_not_connected'
    }
}