


































import { PostAction } from './PostAction';
import PostActionWrapper from "@/components/Post/NewActions/components/PostAction/PostActionWrapper.vue";
import SelectPostActionModalMixin from "@/components/Post/NewActions/components/mixins/SelectPostActionModalMixin";

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue';
import WizardLikeSelect from 'piramis-base-components/src/components/WizardLikeSelect/WizardLikeSelect.vue';

import { Component, Mixins } from 'vue-property-decorator';
import { cloneDeep } from "lodash";

@Component({
  data() {
    return {
      PostAction
    }
  },
  methods: {
    cloneDeep
  },
  components: {
    PostActionWrapper,
    WizardLikeSelect,
    Icon,
  }
})
export default class SelectPostActionModal extends Mixins<SelectPostActionModalMixin<PostAction>>(SelectPostActionModalMixin) {
}
