















import { SelectOption } from "piramis-base-components/src/logic/types";
import getVSelectOptionByValue from 'piramis-js-utils/lib/getVSelectOptionByValue'

import { Component, Prop } from 'vue-property-decorator';
import Vue from "vue";

@Component({
  methods: {
    getVSelectOptionByValue
  }
})
export default class PostLowerBoundReactionView extends Vue {
  @Prop() lowerBound!: number

  @Prop() reaction!: string

  @Prop() reactionOptions!: Array<SelectOption>

  get reactionLabel() {
    return getVSelectOptionByValue(this.reactionOptions, this.reaction).label
  }
}
