import { TestBoardStateCode } from "@/includes/types/Board.types";

export const boardStateInfo = {
    [TestBoardStateCode.UndefinedError]: {
            type: 'Text',
            text: 'board_test_state_undefined_error_help'
    },
    [TestBoardStateCode.CommunicationError]: {
            type: 'Text',
            text: 'board_test_state_communication_error_help'
    },
    [TestBoardStateCode.HandlerError]:{
            type: 'Text',
            text: 'board_test_state_handler_error_help'
    },
    [TestBoardStateCode.BotDomainError]: {
        type: 'Text',
        text: 'board_bot_domain_error_help_message'
    },
    [TestBoardStateCode.ApiError]: {
        type: 'Text',
        text: 'board_api_error_help_message'
    },
}

