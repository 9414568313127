


















import { ColorPickerColors, PickerColors } from '@/includes/types/Post.types'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Emit, Prop } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    ConfigField
  }
})
export default class ColorPicker extends Vue {
  @Prop({ type: String }) color!: string | null

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  @Emit('onAccept')
  updateParentModel(newColorArray): string {
    return newColorArray
  }

  colorsCopy: string | null = null

  get colorPalette(): Array<PickerColors> {
    return ColorPickerColors
  }

  resetColor(): void {
    this.colorsCopy = null
    this.updateParentModel(null)
  }

  onColorSelect(color: string): void {
    if (this.colorsCopy === color) {
      this.resetColor()
    } else {
      this.colorsCopy = color

      this.updateParentModel(this.colorsCopy)
    }
  }

  created(): void {
    this.colorsCopy = this.color
  }
}
