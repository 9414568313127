




















import { InputSetups } from "@/mixins/input-setups";

import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";

import { Component, Mixins, VModel } from 'vue-property-decorator';

@Component({
  components: {
    TimeUnitInput
  }
})
export default class PostActionDelay extends Mixins(InputSetups) {
  @VModel() delay!: number
}
