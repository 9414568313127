var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-reaction-wrapper"},[_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'reactionModel',
        'prefix': 'post_reaction_',
        'options': _vm.reactionOptions,
        'multiple': false,
        'clearable': false,
        'validation': ("option_selected:" + (_vm.reactionOptions.map(function (v) { return v.value; })) + "|required"),
      },
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'lowerBoundModel',
        'prefix': 'post_reaction_',
        'validation': 'required|min_value:1',
        'min': 0
      }
    }}}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }