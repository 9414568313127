

























































































import { InputSetups } from '../../mixins/input-setups'
import { RequestPost } from '@/includes/types/Post.types'
import { ChannelService } from '@/includes/services/ChannelService'
import { ChannelSlot } from '@/includes/types/Board.types'
import { errorNotification } from '@/includes/services/NotificationService'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Emit, Mixins, Prop, VModel } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'

@Component({
  computed: {
    ...mapGetters([ 'getBoardById' ])
  },
  components: {
    ConfigField
  }
})
export default class PostTargetSelect extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {

  @VModel() model!: RequestPost

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  @Emit('onSlotUpdate')
  updateSlot(slots: Array<ChannelSlot> | null): Array<ChannelSlot> | null {
    return slots
  }

  @Emit()
  targetsChange(values: Array<SelectOptionData>):Array<SelectOptionData> {
    return values
  }

  getBoardById!:GetBoardByIdType

  activeKey = 1

  targetOptions: Array<SelectOptionData> = []

  optionsBackup: Array<SelectOptionData> = []

  get boardTargetOptions() {
    return this.$store.getters.boardTargetOptions
  }

  get originOptions(): Array<SelectOptionData> {
    return this.boardTargetOptions
      .slice()
      .filter((o) => this.model.targets && !this.model.targets.includes(o.value))
  }

  changeKey(newKey:number) {
    if (newKey !== this.activeKey) {
      this.activeKey = newKey

      this.onActiveKeyChange()
    }
  }

  onActiveKeyChange(): void {
    this.model.origin = null
    this.model.targets = []
    this.updateSlot(null)
    this.targetOptions = this.optionsBackup
  }

  handleOriginInput(optionValue: SelectOptionData | null): void {
    if (optionValue === null) {
      this.updateSlot(null)
      this.targetOptions = this.optionsBackup
    } else {
      this.getChannelSlots([ optionValue.value ])
      this.targetOptions = this.optionsBackup.filter(option => option.value !== optionValue.value)
    }
  }

  async getChannelSlots(selectedChannels: Array<number>): Promise<void> {
    try {
      const { items } = await ChannelService.getChannelSlots('tg', {
        board_key: this.getBoardById(this.$route.params[ EntityTypes.BOT_ID ])!.board,
        channel_ids: selectedChannels
      })

      if (items.length > 0) {
        this.updateSlot(items)
      } else {
        this.updateSlot(null)
      }
    } catch (e:any) {
      errorNotification(e)
      this.updateSlot(null)
    }
  }

  processChannelSlots(options:Array<SelectOptionData>):void {
    if (options.length) {
      this.getChannelSlots(options.map(v => v.value))
      this.$store.dispatch('updateTargetsWithoutLinkedGroup', options.map(v => v.value))
    } else {
      this.getChannelSlots([])
      this.$store.dispatch('updateTargetsWithoutLinkedGroup', null)
    }
  }

  debounceHandlePostTargetsInput = debounce(this.handlePostTargetsInput, 1000)

  handlePostTargetsInput(values: Array<SelectOptionData>): void {
    this.processChannelSlots(values)
    this.targetsChange(values)

    this.targetOptions = this.optionsBackup
  }

  mounted(): void {
    const targets = this.boardTargetOptions
    let options:Array<SelectOptionData> = []

    if (targets.length === 1) {
      options = [ ...targets ]
    } else {
      options = [ ...targets ]
    }

    if (this.model.targets.length) {
      this.getChannelSlots(this.model.targets)
      this.$store.dispatch('updateTargetsWithoutLinkedGroup', this.model.targets)
    } else {
      this.$store.dispatch('updateTargetsWithoutLinkedGroup', null)
    }

    options.forEach(o => this.targetOptions.push(o))
    this.optionsBackup = [ ...this.targetOptions ]

    if (this.model.origin !== null) {
      this.activeKey = 2
    }
  }
}
