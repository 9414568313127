var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('time-unit-input',{staticClass:"post-action-delay",attrs:{"setup":{
    'func': _vm.modelSetter,
    'args': {
      'model': this,
      'key': 'delay',
      'prefix': 'post_action_parallel_',
      'time24hr': true,
      'settingWrapper': {
        customiseValue: _vm.StepUnit.Minute,
        hasFieldButton: true,
        disabledValue: 0
      },
    }
  }}})}
var staticRenderFns = []

export { render, staticRenderFns }