import { PostActionTypes } from "@/components/Post/NewActions/components/ActionTypes/PostActionTypes";
import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";

import { Type } from "class-transformer";

export class PostActionStructure {
    @Type(() => PostActionBuilder, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: 'type',
            subTypes: PostActionTypes
        }
    })
    action!: PostActionBuilder
}
