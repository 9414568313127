





































































































import { ActionExecuteType } from "@/includes/types/Post.types";
import SelectPostActionMixin from "../mixins/SelectPostActionMixin";
import { PostReaction } from "./PostReaction";
import SelectPostReactionModal from "./SelectPostReactionModal.vue";
import PostLowerBoundReactionView from "./PostLowerBoundReactionView.vue";
import { COMMENTS_EXTRA_GUID, FORWARDS_EXTRA_GUID, VIEWS_EXTRA_GUID } from "@/includes/CONSTANTS";

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue';
import HelpMessage from "piramis-base-components/src/components/HelpMessage/HelpMessage.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";
import {
  ButtonTypes
} from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types";

import { cloneDeep } from "lodash";
import { plainToInstance } from "class-transformer";
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  data() {
    return {
      PostReaction,
      ActionExecuteType
    }
  },
  methods: {
    cloneDeep
  },
  components: {
    PostLowerBoundReactionView,
    SelectPostReactionModal,
    Icon,
    HelpMessage
  }
})
export default class SelectPostReaction extends Mixins<SelectPostActionMixin<PostReaction>>(SelectPostActionMixin) {
  rawActionsToInstance(actions: Array<any>): Array<PostReaction> {
    return plainToInstance(PostReaction, actions)
  }

  get variantsButtons() {
    if (this.postMessage && this.postMessage?.variants?.length) {
      return this.postMessage.variants.reduce((buttons: Array<SelectOption>, variant) => {
        const flatButtons = variant.buttons.flat()

        if (flatButtons.length) {
          flatButtons.forEach(button => {
            if (button.type === ButtonTypes.Reaction) {
              buttons.push({
                label: button.text,
                value: button.guid
              })
            }
          })
        }

        return buttons
      }, [])
    }

    return []
  }

  get reactionOptions(): Array<SelectOption> {
    return [
      { label: this.$t('reaction_extra_views').toString(), value: VIEWS_EXTRA_GUID },
      { label: this.$t('reaction_extra_forwards').toString(), value: FORWARDS_EXTRA_GUID },
      { label: this.$t('reaction_extra_comments').toString(), value: COMMENTS_EXTRA_GUID },
    ].concat(this.variantsButtons)
  }

}
