import { PostType } from "piramis-base-components/src/shared/modules/posting/types";
import MessageEditorWithMediaData
    from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export class PostMessage {
    type!: PostType.Post

    @Type(() => MessageEditorWithMediaData)
    variants!: Array<MessageEditorWithMediaData>
}