import { PostActionBuilder } from "../ActionTypes/PostActionModel";
import PinMessageActionModel from "../ActionTypes/PinMessageAction/PinMessageActionModel";
import PinChatMessageActionModel from "../ActionTypes/PinChatMessageAction/PinChatMessageActionModel";
import PinSilentMessageActionModel from "../ActionTypes/PinSilentMessageAction/PinSilentMessageActionModel";
import PinChatSilentMessageActionModel from "../ActionTypes/PinChatSilentMessageAction/PinChatSilentMessageActionModel";
import UnpinMessageActionModel from "../ActionTypes/UnpinMessageAction/UnpinMessageActionModel";
import UnpinChatMessageActionModel from "../ActionTypes/UnpinChatMessageAction/UnpinChatMessageActionModel";
import SendReplyMessageActionModel from "../ActionTypes/SendReplyMessageAction/SendReplyMessageActionModel";
import SendCommentActionModel from "../ActionTypes/SendCommentAction/SendCommentActionModel";
import EditPostActionModel from "../ActionTypes/EditPostAction/EditPostActionModel";
import SendReplyCommentActionModel from "../ActionTypes/SendReplyCommentAction/SendReplyCommentActionModel";
import DeletePostActionModel from "../ActionTypes/DeletePostAction/DeletePostActionModel";
import NotifyUsersActionModel from "../ActionTypes/NotifyUsersAction/NotifyUsersActionModel";
import ForwardMessageActionModel from "../ActionTypes/ForwardMessageAction/ForwardMessageActionModel";
import ForwardActionModel from "../ActionTypes/ForwardAction/ForwardActionModel";
import NotifyUserActionModel from "../ActionTypes/NotifyUserAction/NotifyUserActionModel";

import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component({})
export default class NewActionsList extends Vue {
    get defaultActions(): Array<PostActionBuilder> {
        return [
            new PinMessageActionModel(),
            new PinChatMessageActionModel(),
            new PinSilentMessageActionModel(),
            new PinChatSilentMessageActionModel(),
            new UnpinMessageActionModel(),
            new UnpinChatMessageActionModel(),
            new SendReplyMessageActionModel(),
            new SendCommentActionModel(),
            new EditPostActionModel(),
            new NotifyUserActionModel(),
            new SendReplyCommentActionModel(),
            new DeletePostActionModel(),
            new NotifyUsersActionModel(),
            new ForwardMessageActionModel(),
            new ForwardActionModel(),
        ]
    }

    get postStylesActions() {
        return this.defaultActions.filter(a => !(a instanceof EditPostActionModel))
    }
}