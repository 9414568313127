



























import ColorPicker from '@/components/Post/ColorPicker.vue'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Prop, VModel } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    ColorPicker,
    ConfigField
  }
})
export default class PostColorSelect extends Vue {
  @VModel() currentColor!: string

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  isDropdownVisible = false

  handlePickerAccept(value:string): void {
    this.currentColor = value
    this.isDropdownVisible = false
  }
}
