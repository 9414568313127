import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { PostActionType } from "@/includes/types/Post.types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export default class DeletePostActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.DeletePostAction;

    icon: string = 'delete_sweep'

    view: VueComponent | undefined;
    valueView: VueComponent | undefined;

}