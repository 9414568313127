var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-shop-fields-wrapper"},[_c('a-spin',{attrs:{"spinning":_vm.dataLoading}},[_c('cash-register-setup',{attrs:{"visible":_vm.createNewCashRegisterIsOpen,"create-cash-register":_vm.createNewCashRegister,"initial-name":_vm.newCashRegisterName},on:{"update:visible":function($event){_vm.createNewCashRegisterIsOpen=$event}}}),_c('create-new-product',{attrs:{"initial-name":_vm.newProductName,"create-product":_vm.createNewProduct,"target-id":_vm.$store.state.boardsState.activeBoard.board},model:{value:(_vm.createNewProductIsOpen),callback:function ($$v) {_vm.createNewProductIsOpen=$$v},expression:"createNewProductIsOpen"}}),_c('create-if-empty',{attrs:{"options":_vm.$store.getters.getActiveCashRegisterOptions,"empty-message":_vm.$t('pi_cash_register_create_empty_message')},on:{"onCreateClick":_vm.handleCashRegisterOption}},[(_vm.$store.state.shopState.cashRegisters !== null)?_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'cashRegisterId',
            'options': _vm.$store.getters.getActiveCashRegisterOptions,
            'taggable': true,
            'clearable': false,
            'createNewIcon': 'plus',
            'notFoundOption': _vm.$t('cash_register_not_found').toString(),
            'validation': 'required',
            'createButton': {
              title: _vm.$t('create_cash_register').toString(),
              callback: _vm.handleCashRegisterOption
            },
            prefix: _vm.prefix
          }
        }}}):_vm._e()],1),_c('create-if-empty',{attrs:{"options":_vm.cashRegisterId ? _vm.products : null,"empty-message":_vm.$t('pi_product_create_empty_message')},on:{"onCreateClick":_vm.handleProductOption}},[(_vm.$store.state.shopState.products !== null)?_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': this,
            'key': 'productId',
            'options': _vm.products,
            'taggable': true,
            'clearable': false,
            'createNewIcon': 'plus',
            'validation': 'required',
            'notFoundOptionText': _vm.$t('product_not_found').toString(),
            'disabled': !_vm.cashRegisterId,
            'createButton': {
              title: _vm.$t('create_product').toString(),
              callback: _vm.handleProductOption
            },
            prefix: _vm.prefix
          }
        }}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }