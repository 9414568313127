import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { ForwarMessageActionMode, PostActionType } from "@/includes/types/Post.types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export default class ForwardMessageActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.ForwardMessageAction;

    mode: ForwarMessageActionMode = ForwarMessageActionMode.Forward
    targets: Array<number> = []

    icon: string = 'undo'
    view: VueComponent | undefined = () => import('./ForwardMessageActionView.vue')
    valueView: VueComponent | undefined;

    isDeprecated = true
    isVisible = false
}