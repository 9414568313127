var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-questionnaire"},[_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.message,
        'key': 'text',
        'validation': 'required',
        'prefix': 'post_questionnaire_',
        'disabled': _vm.disabled
      },
    }}}),(_vm.questionsFrontend.length)?_c('a-checkbox-group',{staticClass:"w-full",model:{value:(_vm.selectedOption),callback:function ($$v) {_vm.selectedOption=$$v},expression:"selectedOption"}},_vm._l((_vm.questionsFrontend),function(option,index){return _c('a-checkbox',{key:option.value,staticClass:"ml-2 mb-2 last:mb-0 flex justify-between items-center",attrs:{"value":option.value,"disabled":_vm.isCheckboxDisabled(option.value)}},[(!option.visible)?_c('span',[_vm._v(" "+_vm._s(option.value)+" ")]):_vm._e(),(option.visible)?_c('a-input',{on:{"pressEnter":function($event){return _vm.handleQuestionItemEditClick(option, index)}},model:{value:(option.value),callback:function ($$v) {_vm.$set(option, "value", $$v)},expression:"option.value"}}):_vm._e(),_c('div',{staticClass:"w-full text-right"},[_c('a-button',{attrs:{"icon":"edit"},on:{"click":function($event){return _vm.handleQuestionItemEditClick(option, index)}}}),_c('a-button',{staticClass:"ml-2",attrs:{"type":"danger","icon":"delete","disabled":_vm.disabled || _vm.message.type === _vm.PostType.Quiz && _vm.message.correct_option_id === option.value},on:{"click":function (){ return _vm.questionsFrontend.splice(index, 1); }}})],1)],1)}),1):_vm._e(),_c('div',{staticClass:"add_value_wrapper flex items-end gap-2"},[_c('text-input',{staticClass:"w-full mb-0",attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'newOption',
          'prefix': 'post_questionnaire_',
          'disabled': _vm.disabled
        },
      }},on:{"pressEnter":function($event){_vm.optionCantBeAdd ? false : _vm.addNewOption()}}}),_c('a-button',{staticClass:"mb-1",attrs:{"icon":"plus","disabled":_vm.optionCantBeAdd},on:{"click":_vm.addNewOption}})],1),(_vm.message.type === _vm.PostType.Quiz)?_c('div',{staticClass:"quiz_fields w-full"},[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.message,
          'key': 'explanation',
          'validation': 'required',
          'prefix': 'post_questionnaire_',
          'disabled': _vm.disabled
        },
      }}})],1):_vm._e(),(_vm.message.type === _vm.PostType.Poll)?_c('switch-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.message,
        'key': 'allows_multiple_answers',
        'prefix': 'post_questionnaire_',
        'disabled':_vm.disabled
      }
    }}}):_vm._e(),_c('switch-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.message,
        'key': 'is_anonymous',
        'prefix': 'post_questionnaire_',
        'disabled':_vm.disabled
      }
    }}}),_c('time-unit-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args':{
        'model': _vm.message,
        'key': 'open_period',
        'prefix': 'post_questionnaire_',
        'validation': 'min_value:0',
        'step': _vm.StepUnit.Second,
        'units': [_vm.StepUnit.Second, _vm.StepUnit.Minute],
        'disabled':_vm.disabled
      },
    }}}),(_vm.message.open_period > 600)?_c('span',{staticClass:"ml-2",staticStyle:{"color":"rgba(var(--a-danger), 1)","font-size":"80%"}},[_vm._v(" "+_vm._s(_vm.$t('post_quiz_period_warning'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }