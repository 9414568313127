var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"period-settings"},[(_vm.model.schedule.period)?_c('time-unit-input',{staticClass:"period-settings__time-unit",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model.schedule.period,
        'key': 'interval',
        'step': _vm.StepUnit.Minute,
        'prefix': 'post_',
        'disabled': _vm.disabledFields,
        'initUnit': _vm.model.schedule.period.unit,
        'mode': _vm.TimeUnitInputMode.Fixed,
        'onUnitChange': _vm.onUnitChange
      },
    }}}):_vm._e(),_c('period-conditions',{attrs:{"period":_vm.model.schedule.period,"initConditions":_vm.initConditions},model:{value:(_vm.scheduleCondition),callback:function ($$v) {_vm.scheduleCondition=$$v},expression:"scheduleCondition"}}),(_vm.scheduleDates && _vm.model.schedule.period && _vm.model.schedule.period.interval > 0)?_c('div',{staticClass:"schedule-dates mt-2 w-full h-full"},[(_vm.model.post.message.type === _vm.PostType.Post)?_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': this,
          'key': 'removePrevMessage',
          'disabled': _vm.removePrevPostDisabled
        },
      }}}):_vm._e(),_c('a-spin',{attrs:{"spinning":_vm.datesLoading}},[(_vm.scheduleDates.length)?_c('a-alert',[_c('div',{attrs:{"slot":"description"},slot:"description"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.scheduleDate)}}),_c('ul',{staticClass:"schedule-dates__list flex flex-wrap mt-2"},_vm._l((_vm.scheduleDates),function(date,index){return _c('li',{key:index,staticClass:"w-full md:w-1/3 mb-2"},[_vm._v(" "+_vm._s(date)+" ")])}),0)])]):_c('a-alert',{attrs:{"type":"error"}},[_c('span',{attrs:{"slot":"description"},slot:"description"},[_vm._v(" "+_vm._s(_vm.$t('schedule_dates_empty'))+" ")])])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }