import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { PostActionType } from "@/includes/types/Post.types";
import { PostMessage } from "../PostMessage";

import { PostType } from "piramis-base-components/src/shared/modules/posting/types";
import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { Expose, plainToInstance, Type } from "class-transformer";

export default class EditPostActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.EditPostAction;

    @Type(() => PostMessage)
    @Expose({ name: 'message' })
    private _message: PostMessage = plainToInstance(PostMessage, {
        type: PostType.Post,
        variants: [ {
            'pin': false,
            'text': '',
            'buttons': [],
            'disable_notify': false,
            'remove_previous': false,
            'disable_link_preview': false,
            'attachments': [],
            'send_after': 0,
            'remove_after': 0,
            'protect_content': false
        } ]
    })

    get message(): PostMessage {
        return this._message;
    }

    set message(value: PostMessage | any) {
        if (value instanceof PostMessage) {
            this._message = value;
        } else {
            this._message = plainToInstance(PostMessage, value);
        }
    }

    icon: string = 'edit_note'
    view: VueComponent | undefined = () => import('./EditPostActionView.vue')
    valueView: VueComponent | undefined = () => import('../components/PostActionMessage/PostActionMessageValuePreview.vue')
}