import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { ForwardActionTarget, PostActionType } from "@/includes/types/Post.types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export default class ForwardActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.ForwardAction;

    targets: Array<ForwardActionTarget> = []
    m: Array<ForwardActionTarget> = []

    icon: string = 'undo'
    view: VueComponent | undefined = () => import('./ForwardActionView.vue')
    valueView: VueComponent | undefined = () => import('./ForwardActionValue.vue')
}