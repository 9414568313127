




































































































































































































































































import { PostCommentsStateTypes, Style, StyleFields, WatermarkPosition, } from '@/includes/types/Post.types'
import { InputSetups } from '@/mixins/input-setups'
import PostColorSelect from './PostColorSelect.vue'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import { defaultStyleModel } from '@/includes/logic/Style/constant'
import SelectPostAction from "@/components/Post/NewActions/components/PostAction/SelectPostAction.vue";
import NewActionsList from "@/components/Post/NewActions/components/mixins/NewActionsList";

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { FileType } from 'piramis-base-components/src/components/File/types'
import { MessageEditorWithMediaTab } from 'piramis-base-components/src/components/NewMessageEditors/types'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { PostType } from 'piramis-base-components/src/shared/modules/posting/types'
import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'

import { Mixins, Prop, VModel, Watch, } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    SelectPostAction,
    Accordion,
    PostColorSelect,
    MultiMessageEditorWithMediaInput
  },
  data() {
    return {
      FileType,
      MessageEditorWithMediaTab,
      EditorButtonsHelpView,
      EntityTypes,
      PostType
    }
  }
})
export default class PostStylesSetup extends Mixins(InputSetups, UseFields, NewActionsList) {
  @VModel({ 'type': Object }) style !: Style

  @Prop({ required: true, type: String }) board!: string

  @Prop({ type: String, default: null }) postType!: PostType | null

  @Prop({ type: Boolean, default: false }) disabled !: boolean

  @Prop({ type: String, default: null }) postActionType !: 'new' | 'copy' | 'edit' | 'edit-published' | 'show-suggest' | 'edit-suggest'

  @Prop({ type: Boolean, required: false, default: true }) showDelayedActions!: boolean

  @Prop({ type: Array, required: false }) pickVisible!: StyleFields

  @Prop({ type: Array, required: false }) omitVisible!: StyleFields

  @Watch('styleCopy')
  onStyleCopyChange(value: Style) {
    this.style = value
  }

  file: any = null

  styleCopy: Style = {} as Style

  get watermarkTypes(): Array<SelectOption> {
    return [
      { 'label': this.$t('board_style_watermark_none').toString(), 'value': 'None' },
      { 'label': this.$t('board_style_watermark_text').toString(), 'value': 'Text' },
      { 'label': this.$t('board_style_watermark_file').toString(), 'value': 'File' },
    ]
  }

  get postCommentsStateOptions(): Array<SelectOption> {
    return [
      { 'label': this.$t('post_comments_state_none').toString(), 'value': PostCommentsStateTypes.None },
      { 'label': this.$t('post_comments_state_disabled').toString(), 'value': PostCommentsStateTypes.Disabled },
      { 'label': this.$t('post_comments_state_linear').toString(), 'value': PostCommentsStateTypes.Linear },
    ]
  }

  get compressLevelOptions(): Array<SelectOption> {
    return [
      { 'label': this.$t('compress_level_not_compress').toString(), 'value': null },
      { 'label': '4320p', 'value': 4320 },
      { 'label': '2160p', 'value': 2160 },
      { 'label': '1440p', 'value': 1440 },
      { 'label': '1080p', 'value': 1080 },
      { 'label': '720p', 'value': 720 },
      { 'label': '576p', 'value': 576 },
      { 'label': '480p', 'value': 480 },
      { 'label': '360p', 'value': 360 },
      { 'label': '240p', 'value': 240 },
    ]
  }

  get watermarkPosition(): Array<SelectOption> {
    return [
      { 'label': this.$t('watermark_position_flip').toString(), 'value': WatermarkPosition.Flip },
      { 'label': this.$t('watermark_position_center').toString(), 'value': WatermarkPosition.Center },
      { 'label': this.$t('watermark_position_top_right').toString(), 'value': WatermarkPosition.TopRight },
      { 'label': this.$t('watermark_position_top_left').toString(), 'value': WatermarkPosition.TopLeft },
      { 'label': this.$t('watermark_position_bottom_right').toString(), 'value': WatermarkPosition.BottomRight },
      { 'label': this.$t('watermark_position_bottom_left').toString(), 'value': WatermarkPosition.BottomLeft },
    ]
  }

  actionsAccordionOpen = false

  isFieldVisible(key: StyleFields[0]) {
    if (this.pickVisible) {
      return this.pickVisible.includes(key)
    } else if (this.omitVisible) {
      return !this.omitVisible.includes(key)
    } else {
      return true
    }
  }

  onSelectChange(): void {
    if (this.styleCopy) this.styleCopy.watermark_data = null
  }

  processDeprecatedFields() {
    if (this.style.data && this.style.data?.disable_comments !== undefined) {
      delete this.style.data?.disable_comments
    }
  }

  created() {
    if (this.style) {
      if (this.style.data === null) {
        this.style.data = cloneDeep(defaultStyleModel.data)
      }

      this.processDeprecatedFields()

      this.styleCopy = cloneDeep(this.style)

      if (this.style.actions && this.style.actions.length) {
        this.actionsAccordionOpen = true
      }
    }
  }
}
