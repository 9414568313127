








































































































































































import { Conditions, Period } from '@/includes/types/Post.types'
import { InputSetups } from '@/mixins/input-setups'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import HelpMessage from 'piramis-base-components/src/components/HelpMessage/HelpMessage.vue'

import { Mixins, Prop, VModel, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import Component from 'vue-class-component'
import flatPickr from 'vue-flatpickr-component'

@Component({
  components: {
    flatPickr,
    ConfigField,
    ValidationProvider,
    AListCustom,
    AListCustomItem,
    HelpMessage
  }
})
export default class PeriodConditions extends Mixins(InputSetups, UseFields) {

  selectedTab = 'TimeInterval'

  @VModel() scheduleCondition !: Array<Conditions>

  @Watch('scheduleConditionsModel')
  onScheduleConditionsChange() {
    this.scheduleCondition = this.scheduleConditionsModel.filter((c: any) => c.values.length && !c.values.includes(0) && this.intervalByType(c.type).isIncluded)
  }

  @Prop() period !: Period

  @Prop() initConditions !: Array<Conditions> | null

  conditionModalOpen = false

  popupActive = false

  scheduleConditionsModel: any = []

  activeConditionType = ''

  from = ''

  to = ''

  dayWeek: Array<string> = []

  dayMonth: Array<string> = []

  dateIntervalModel: Array<string> = []

  intervalsFrontendList = [
    {
      type: 'TimeInterval',
      name: this.$t('popup_conditions_time_interval'),
      isIncluded: true,
      values: []
    },
    {
      type: 'DateInterval',
      name: this.$t('popup_conditions_date_interval'),
      isIncluded: true,
      values: []
    },
    {
      type: 'DayOfWeek',
      name: this.$t('popup_conditions_day_of_week'),
      isIncluded: true,
      values: []
    },
    {
      type: 'DayOfMonth',
      name: this.$t('popup_conditions_day_of_month'),
      isIncluded: true,
      values: []
    }
  ]

  handleCancel(): void {
    this.popupActive = false
  }

  get isDisabled(): boolean {
    if (this.selectedTab === 'TimeInterval') {
      return !!this.to && !!this.from
    }
    if (this.selectedTab === 'DateInterval') {
      return this.dateIntervalModel.length === 2
    }
    if (this.selectedTab === 'DayOfWeek') {
      return !!this.dayWeek.length
    }
    if (this.selectedTab === 'DayOfMonth') {
      return !!this.dayMonth.length
    }
    return false
  }

  addIntervalToFilters() {
    const interval: Array<string> = []
    switch (this.selectedTab) {
      case 'TimeInterval':
        interval[0] = this.from
        interval[1] = this.to
        this.filtersModelSetter('TimeInterval', interval)
        this.from = ''
        this.to = ''
        break
      case 'DateInterval':
        interval[0] = this.dateIntervalModel[0]
        interval[1] = this.dateIntervalModel[1]
        this.filtersModelSetter('DateInterval', interval)
        this.dateIntervalModel = []
        break
      case 'DayOfWeek':
        const days = [ ...Array.from(new Set(this.dayWeek)) ]
        this.filtersModelSetter('DayOfWeek', days)
        break
      case 'DayOfMonth':
        this.filtersModelSetter('DayOfMonth', this.dayMonth)
        break
    }
    this.conditionModalOpen = false
  }

  removeIntervalItemFromLists(index: number, filter: string) {
    const currentInterval = this.intervalByType(filter)

    if ([ 'DayOfWeek', 'DayOfMonth' ].includes(filter)) {
      this.scheduleConditionsModel = this.scheduleConditionsModel.filter((f: any) => f.type !== filter)
      currentInterval.values.splice(0, 1)
    } else {
      this.scheduleConditionsModel = this.scheduleConditionsModel.map((f: Conditions) => {
        if (f.type === filter) {
          f.values.splice(index * 2, 2)
          return f
        }
        return f
      })
      currentInterval.values.splice(index, 1)
    }

    this.scheduleConditionsModel = this.scheduleConditionsModel.filter((f: any) => f.values.length)
  }

  intervalByType(intervalType: string): any {
    return this.intervalsFrontendList.find(interval => interval.type === intervalType)
  }

  filtersModelSetter(intervalType: string, value: any[] | string) {
    const scheduleTypes = [ 'TimeInterval', 'DateInterval', 'DayOfWeek', 'DayOfMonth' ]
    const intervals = [ 'TimeInterval', 'DateInterval' ]
    const currentIntervalType = this.intervalByType(intervalType)

    if (!this.scheduleConditionsModel.some((f: Conditions) => f.type === intervalType) && currentIntervalType) {
      this.scheduleConditionsModel.push({
        'type': intervalType,
        'values': intervalType === 'DayOfMonth' && typeof value === 'string' ? [ ...value.split(', ') ] : [ ...value ]
      })
      currentIntervalType.values.push(this.valueFormatString(intervalType, value))
    } else if (scheduleTypes.includes(intervalType) && currentIntervalType) {
      if (intervals.includes(intervalType)) {
        currentIntervalType.values.push(this.valueFormatString(intervalType, value))
      } else {
        currentIntervalType.values = [ ...this.valueFormatString(intervalType, value) ]
      }
      this.scheduleConditionsModel.map((f: any) => {
        if (f.type === intervalType && f.type === this.selectedTab) {
          intervals.includes(intervalType) ? f.values.push(...value) : f.values = typeof value === 'string' ? value.split(', ') : value
          return f
        }
        return f
      })
    } else {
      this.scheduleConditionsModel.map((f: any) => {
        if (f.type === intervalType) {
          return f.values = intervalType === 'DayOfMonth' && typeof value === 'string' ? value.split(', ') : value
        }
        return f
      })
    }
  }

  valueFormatString(intervalType: string, values: any): any {
    switch (intervalType) {
      case 'DayOfMonth':
        return `${ values } ${ this.$t('chosen_month_days') }`
      case 'DayOfWeek':
        let convertWeekDays: any[] = []
        values.sort().forEach((i: any) => {
          convertWeekDays.push([
            this.$t('post_weekday_monday'),
            this.$t('post_weekday_tuesday'),
            this.$t('post_weekday_wednesday'),
            this.$t('post_weekday_thursday'),
            this.$t('post_weekday_friday'),
            this.$t('post_weekday_saturday'),
            this.$t('post_weekday_sunday'),
          ][i - 1])
        })
        return `${ this.$t('post_weekday_from') } ${ convertWeekDays.join(', ') }`
      default:
        return `${ this.$t('date_picker_from') } ${ values[0] } ${ this.$t('date_picker_to') } ${ values[values.length - 1] }`
    }
  }

  processInterval(filters: any) {
    filters.values.forEach((v: any, index: number, elements: any) => {
      if (index % 2 === 0) {
        if (filters.type === 'TimeInterval') {
          this.from = v
          this.to = elements[index + 1]
        } else {
          this.dateIntervalModel = [ v, elements[index + 1] ]
        }
        this.selectedTab = filters.type
        this.addIntervalToFilters()
      }
    })
  }

  processScheduleConditions(): void {
    if (this.initConditions) {
      if (this.initConditions.some((f: Conditions) => f.type === 'DayOfMonth')) {
        this.selectedTab = 'DayOfMonth'
        this.dayMonth = this.initConditions.find((f: Conditions) => f.type === 'DayOfMonth')?.values.join(', ')
        this.intervalByType('DayOfMonth').isIncluded = true
        this.addIntervalToFilters()
      }
      if (this.initConditions.some((f: Conditions) => f.type === 'DayOfWeek')) {
        this.selectedTab = 'DayOfWeek'
        this.dayWeek = this.initConditions.find((f: Conditions) => f.type === 'DayOfWeek')?.values.map((v: any) => Number(v))
        this.intervalByType('DayOfWeek').isIncluded = true
        this.addIntervalToFilters()
      }
      if (this.initConditions.some((f: Conditions) => f.type === 'DateInterval')) {
        this.intervalByType('DateInterval').isIncluded = true
        this.processInterval(this.initConditions.find((f: Conditions) => f.type === 'DateInterval'))
      }
      if (this.initConditions.some((f: Conditions) => f.type === 'TimeInterval')) {
        this.intervalByType('TimeInterval').isIncluded = true
        this.processInterval(this.initConditions.find((f: Conditions) => f.type === 'TimeInterval'))
      }
    }
  }

  created(): void {
    this.processScheduleConditions()
  }
}
