


































import { PostReaction } from "@/components/Post/NewActions/components/PostReaction/PostReaction";
import { InputSetups } from "@/mixins/input-setups";

import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";

import { Component, PropSync, Mixins, Prop } from 'vue-property-decorator';
import { extend } from "vee-validate";

@Component({
  components: {
    NumberInput,
    SelectInput,
  }
})
export default class PostReactionActionWrapper extends Mixins(InputSetups) {
  @PropSync('lowerBound') lowerBoundModel!: PostReaction['lower_bound']

  @PropSync('reaction') reactionModel!: PostReaction['reaction']

  @Prop() reactionOptions!: Array<SelectOption>

  created() {
    extend('option_selected', {
      params: [ 'options' ],
      validate: (value: SelectOption, params) => {
        const values = params['options']

        if (value) {
          return !!values.find(v => v === value.value)
        }

        return true
      },
      message: this.$t('option_not_selected_error_title').toString()
    })
  }
}
