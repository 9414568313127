var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-target-select"},[_c('config-field',{attrs:{"title":_vm.$t('post_publish_variant_title'),"help-message":_vm.$t('post_publish_variant_help_message', [_vm.$store.state.boardsState.activeBoard.title])}},[_c('div',{staticClass:"button-wrapper mt-1 flex flex-col md:flex-row"},[_c('a-button',{staticClass:"btn-wrap",class:{'active': _vm.activeKey === 1},attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.changeKey(1)}}},[_vm._v(" "+_vm._s(_vm.$t('send_post_to_channel'))+" ")]),_c('a-button',{staticClass:"btn-wrap ml-0 md:ml-2 mt-2 md:mt-0",class:{'active': _vm.activeKey === 2},attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.changeKey(2)}}},[_vm._v(" "+_vm._s(_vm.$t('forward_post_to_channel'))+" ")])],1)]),_c('transition-group',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.activeKey === 1)?_c('div',{key:"send"},[_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.model,
            'key': 'targets',
            'prefix': 'post_',
            'validation': 'required',
            'options': _vm.targetOptions,
            'multiple': true,
            'disabled': _vm.disabled,
            'selectAll': true
          },
        }},on:{"input":_vm.debounceHandlePostTargetsInput}})],1):_vm._e(),(_vm.activeKey === 2)?_c('div',{key:"forward"},[_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.model,
            'key': 'origin',
            'prefix': 'post_',
            'validation': 'required',
            'options': _vm.originOptions,
            'multiple': false,
            'disabled': _vm.disabled
          },
        }},on:{"input":_vm.handleOriginInput}}),_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.model,
            'key': 'targets',
            'prefix': 'post_with_origin_',
            'validation': 'required',
            'options': _vm.targetOptions,
            'multiple': true,
            'disabled': _vm.disabled
          },
        }}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }