












































































import { InputSetups } from '../../mixins/input-setups'
import { RequestPost } from '@/includes/types/Post.types'
import { successNotification } from "@/includes/services/NotificationService";

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { warningNotificationWrapper } from "piramis-base-components/src/logic/helpers/NotificationService";
import {
  CreateProductModel,
  ProductState,
  ProductType
} from "piramis-base-components/src/components/Shop/Products/types";
import CreateIfEmpty from 'piramis-base-components/src/components/CreateIfEmpty.vue'
import CreateNewProduct from 'piramis-base-components/src/components/Shop/Products/CreateNewProduct.vue'
import { CreateCashRegisterModel } from "piramis-base-components/src/components/Shop/CashRegisters/types";
import CashRegisterSetup from 'piramis-base-components/src/components/Shop/CashRegisters/CashRegisterSetup.vue';

import { Component, Mixins, Prop, PropSync, VModel } from 'vue-property-decorator'

@Component({
  components: {
    CreateIfEmpty,
    CreateNewProduct,
    CashRegisterSetup
  }
})
export default class PostShopFieldsWrapper extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() model!: RequestPost

  @PropSync('productIdModel') productId!: null | number

  @PropSync('cashRegisterIdModel') cashRegisterId!: null | number

  @Prop({ type:String, default: '', required: false }) prefix!:string

  newCashRegisterName = ''

  newProductName = ''

  createNewCashRegisterIsOpen = false

  createNewProductIsOpen = false

  dataLoading = false

  createNewCashRegister(cashRegister: CreateCashRegisterModel) {
    return this.$store.dispatch('createCashRegister', Object.assign({
      board_key: this.$store.state.boardsState.activeBoard!.board,
    }, cashRegister))
      .then((register) => {
        this.inputCashRegister(register.id)

        return register
      })
  }

  createNewProduct(product: CreateProductModel): Promise<void> {
    return new Promise(resolve => {
      this.$store.dispatch('createProduct', Object.assign({
        type: ProductType.Service,
      }, product))
        .then(({ id, currency }) => {
          this.inputNewProduct({ id, currency })
          successNotification(this.$t('success').toString())
        })
        .finally(() => resolve())
    })
  }

  handleCashRegisterOption(option?: Pick<SelectOption, 'label'>): void {
    if (option) {
      this.newCashRegisterName = option.label
    }

    this.createNewCashRegisterIsOpen = true
  }

  handleProductOption(option?: Pick<SelectOption, 'label'>): void {
    if (option) {
      this.newProductName = option.label
    }

    this.createNewProductIsOpen = true
  }

  get products(): Array<{ label: string, value: any }> {
    if (this.$store.state.shopState.cashRegisters !== null && this.$store.state.shopState.products !== null) {
      const cashRegister = this.$store.state.shopState.cashRegisters!.find((cashRegister) => cashRegister.id === this.cashRegisterId)

      if (cashRegister) {
        return this.$store.state.shopState.products!
          .filter((product) => product.currency === cashRegister.currency && product.state === ProductState.Active)
          .map((product) => {
            return {
              label: product.name,
              value: product.id
            }
          })
      }

      return []
    }

    return []
  }

  inputCashRegister(id: number): void {
    this.cashRegisterId = id
  }

  inputNewProduct({ id, currency }: { id: number, currency: string }): void {
    const cash = this.$store.state.shopState.cashRegisters!.find((cash) => cash.id === this.cashRegisterId)

    if (cash) {
      if (cash.currency === currency) {
        this.productId = id
      } else {
        warningNotificationWrapper(this.$t('warning').toString(), this.$t('inserted_product_currency_is_not_valid').toString())
      }
    }
  }

  requestShopData(): void {
    let promises: Array<Promise<any>> = []

    if (!this.$store.state.shopState.products) {
      promises.push(this.$store.dispatch('getProducts', { board_key: this.$store.state.boardsState.activeBoard!.board }))
    }
    if (!this.$store.state.shopState.cashRegisters) {
      promises.push(this.$store.dispatch('getCashRegisters', { board_key: this.$store.state.boardsState.activeBoard!.board }))
    }

    this.dataLoading = true

    Promise.all(promises)
      .finally(() => this.dataLoading = false)
  }

  created() {
    this.requestShopData()
  }
}
