













import { atSignedLogin } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'

import Component from 'vue-class-component'
import Vue from 'vue'
import { Prop, VModel } from 'vue-property-decorator'

@Component({})
export default class TestStateInfoModal extends Vue {
  @VModel() isPopupOpen!: boolean

  @Prop() info!: any

  @Prop({ type: String, required: false }) botUsername!: string

  @Prop({ type: String, required: false }) channelUsername!: string

  get infoTranslation() {
    return this.$t(this.info.text, {
      bot: this.botUsername ?? this.defaultBotUsername,
      channel: this.channelUsername ?? this.defaultChannelUsername
    })
  }

  get defaultBotUsername (){
    return this.$store.getters.activeBoard && atSignedLogin(this.$store.state.boardsState.activeBoard!.title)
  }

  get defaultChannelUsername() {
    return this.$store.state.channelsState.activeChannel && atSignedLogin(this.$store.state.channelsState.activeChannel?.username ?? '')
  }
}
