import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { PostActionType } from "@/includes/types/Post.types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export default class PinSilentMessageActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.PinSilentMessageAction;

    icon: string = 'unsubscribe'

    view: VueComponent | undefined;
    valueView: VueComponent | undefined;

}