






































import PostActionWrapper from "@/components/Post/NewActions/components/PostAction/PostActionWrapper.vue";
import SelectPostActionModalMixin from "@/components/Post/NewActions/components/mixins/SelectPostActionModalMixin";
import { PostReaction } from "@/components/Post/NewActions/components/PostReaction/PostReaction";
import PostReactionWrapper
  from "@/components/Post/NewActions/components/PostReaction/PostReactionWrapper.vue";

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue';
import { SelectOption } from "piramis-base-components/src/logic/types";
import WizardLikeSelect from 'piramis-base-components/src/components/WizardLikeSelect/WizardLikeSelect.vue';

import { Component, Mixins, Prop } from 'vue-property-decorator';
import { cloneDeep } from "lodash";

@Component({
  data() {
    return {
      PostReaction
    }
  },
  methods: {
    cloneDeep
  },
  components: {
    PostReactionWrapper,
    PostActionWrapper,
    WizardLikeSelect,
    Icon,
  }
})
export default class SelectPostReactionModal extends Mixins<SelectPostActionModalMixin<PostReaction>>(SelectPostActionModalMixin) {
  @Prop() reactionOptions!: Array<SelectOption>
}
