import { PostActionType } from "@/includes/types/Post.types";
import { PostActionBuilder } from "./PostActionModel";
import DeletePostActionModel from "./DeletePostAction/DeletePostActionModel";
import EditPostActionModel from "./EditPostAction/EditPostActionModel";
import ForwardActionModel from "./ForwardAction/ForwardActionModel";
import NotifyUserActionModel from "./NotifyUserAction/NotifyUserActionModel";
import NotifyUsersActionModel from "./NotifyUsersAction/NotifyUsersActionModel";
import PinChatMessageActionModel from "./PinChatMessageAction/PinChatMessageActionModel";
import PinChatSilentMessageActionModel from "./PinChatSilentMessageAction/PinChatSilentMessageActionModel";
import PinMessageActionModel from "./PinMessageAction/PinMessageActionModel";
import PinSilentMessageActionModel from "./PinSilentMessageAction/PinSilentMessageActionModel";
import SendCommentActionModel from "./SendCommentAction/SendCommentActionModel";
import SendReplyCommentActionModel from "./SendReplyCommentAction/SendReplyCommentActionModel";
import SendReplyMessageActionModel from "./SendReplyMessageAction/SendReplyMessageActionModel";
import UnpinChatMessageActionModel from "./UnpinChatMessageAction/UnpinChatMessageActionModel";
import UnpinMessageActionModel from "./UnpinMessageAction/UnpinMessageActionModel";
import ForwardMessageActionModel from "./ForwardMessageAction/ForwardMessageActionModel";

import { ClassConstructor } from "class-transformer";

export const PostActionTypes: Array<{ name: PostActionType, value: ClassConstructor<PostActionBuilder> }> = [
    { name: PostActionType.DeletePostAction, value: DeletePostActionModel },
    { name: PostActionType.EditPostAction, value: EditPostActionModel },
    { name: PostActionType.ForwardAction, value: ForwardActionModel },
    { name: PostActionType.ForwardMessageAction, value: ForwardMessageActionModel }, /**  @deprecated **/
    { name: PostActionType.NotifyUserAction, value: NotifyUserActionModel },
    { name: PostActionType.NotifyUsersAction, value: NotifyUsersActionModel },
    { name: PostActionType.PinChatMessageAction, value: PinChatMessageActionModel },
    { name: PostActionType.PinChatSilentMessageAction, value: PinChatSilentMessageActionModel },
    { name: PostActionType.PinMessageAction, value: PinMessageActionModel },
    { name: PostActionType.PinSilentMessageAction, value: PinSilentMessageActionModel },
    { name: PostActionType.SendCommentAction, value: SendCommentActionModel },
    { name: PostActionType.SendReplyCommentAction, value: SendReplyCommentActionModel },
    { name: PostActionType.SendReplyMessageAction, value: SendReplyMessageActionModel },
    { name: PostActionType.UnpinChatMessageAction, value: UnpinChatMessageActionModel },
    { name: PostActionType.UnpinMessageAction, value: UnpinMessageActionModel },
]