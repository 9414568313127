import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { PostActionType } from "@/includes/types/Post.types";
import { WizardLikeList } from "@/components/Post/NewActions/components/WizardLikeSelect/types";
import EditPostActionModel from "@/components/Post/NewActions/components/ActionTypes/EditPostAction/EditPostActionModel";
import { PostActionStructure } from "../ActionTypes/PostActionStructure";

import Vue from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import { cloneDeep } from "lodash";
import { ClassConstructor, plainToInstance } from "class-transformer";

@Component({})
export default class SelectPostActionModalMixin<T extends PostActionStructure> extends Vue {
    @Prop({ type: Array, required: true, default: () => [] }) actionTypes!: Array<PostActionBuilder>

    @Prop() currentAction!: T | null

    @Prop() disabled!: boolean

    @Prop() postMessage!: any

    @Prop({ type:Boolean, default: false }) validateActions!:boolean

    @Emit()
    updateAction<T extends PostActionStructure>(localActions: T) {
        return localActions
    }

    @Emit()
    resetState() {
        return null
    }

    @Watch('currentAction')
    onCurrentConditionChange(value: T | null) {
        if (value) {
            this.currentKey = value.action.type
        } else {
            this.currentKey = value
        }
    }

    actionCopy: PostActionStructure | null = null

    isModalOpen = false

    currentKey: PostActionType | null = null

    get items(): WizardLikeList {
        return [
            {
                type: "Simple",
                items: this.actionTypes.map(action => {
                    return {
                        key: action.type,
                        title: this.$t(action.actionTitleKey()).toString(),
                        helpMessage: this.$te(action.actionHelpMessageKey()) ? this.$t(action.actionHelpMessageKey()).toString() : null,
                        icon: action.icon,
                        disabled: {
                            state: () => action.isDisabled() && this.validateActions,
                            helpMessage: this.$te(action.disabledMessageKey()) ? this.$t(action.disabledMessageKey(), {
                                channels: this.$store.state.targetsWithoutLinkedGroup?.map(t => t.title)?.join(', '),
                                bot: this.$store.state.boardsState.activeBoard?.title
                            }).toString() : null,
                        },
                        isVisible: action?.isVisible ?? true
                    }
                })
            }
        ]
    }

    onSelectItem(type: PostActionType, classConstructor: ClassConstructor<T>) {
        if (this.currentAction && this.currentAction.action.type === type) {
            this.actionCopy = cloneDeep(this.currentAction)
        } else {
            const action = this.actionTypes.find(a => a.type === type)

            if (action) {
                const postAction = plainToInstance(classConstructor, { action })

                if (this.postMessage && postAction.action instanceof EditPostActionModel) {
                    postAction.action.message = cloneDeep(this.postMessage)
                }

                if (postAction) {
                    this.actionCopy = cloneDeep(postAction)
                }
            }
        }
    }

    onSaveClick() {
        if (this.actionCopy) {
            this.updateAction(this.actionCopy)
        }
    }
}