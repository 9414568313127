import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { PostActionType } from "@/includes/types/Post.types";
import store from "@/store/store";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export default class UnpinChatMessageActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.UnpinChatMessageAction;

    icon: string = 'near_me_disabled'

    view: VueComponent | undefined;
    valueView: VueComponent | undefined;

    isDisabled() {
        const targetsWithoutLinkedGroup = store.state.targetsWithoutLinkedGroup

        if (targetsWithoutLinkedGroup === null) {
            return true
        }

        if (Array.isArray(targetsWithoutLinkedGroup)) {
            return !!targetsWithoutLinkedGroup.length
        }

        return true
    }

    disabledMessageKey(): string {
        return 'post_action_group_not_connected'
    }
}