import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { PostActionType } from "@/includes/types/Post.types";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";
import MessageEditorWithMediaData
    from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Exclude, plainToInstance, Type } from "class-transformer";
import { PostType } from "piramis-base-components/src/shared/modules/posting/types";
import GetUserInfoServiceImpl from "@/components/Post/NewActions/GetUserInfoService";
import store from '@/store/store'

export default class NotifyUsersActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.NotifyUsersAction;

    @Type(() => MessageEditorWithMediaData)
    message: Array<MessageEditorWithMediaData> = plainToInstance(MessageEditorWithMediaData, [ {
            'pin': false,
            'text': '',
            'buttons': [],
            'disable_notify': false,
            'remove_previous': false,
            'disable_link_preview': false,
            'attachments': [],
            'send_after': 0,
            'remove_after': 0,
            'protect_content': false
        } ])

    users: Array<number> = []

    @Exclude()
    usersInfoService = new GetUserInfoServiceImpl(store.getters.activeBoard!.board)

    icon: string = 'notifications'
    view: VueComponent | undefined = () => import('./NotifyUsersActionView.vue')
    valueView: VueComponent | undefined = () => import('./NotifyUsersActionValue.vue')
}