import { TestChannelStateCode } from "@/includes/types/Board.types";

export const channelStateInfo = {
    [TestChannelStateCode.ApiError]: {
        type: 'Text',
        text: 'board_api_error_help_message'
    },
    [TestChannelStateCode.ChannelNotFoundError]: {
        type: 'Text',
        text: 'add_bot_to_channel_help_message'
    },
    [TestChannelStateCode.ChannelPermissionSendMessageError]: {
        type: 'Text',
        text: 'channel_test_state_channel_permission_send_message_error'
    },
    [TestChannelStateCode.ChannelPermissionDeleteMessageError]: {
        type: 'Text',
        text: 'channel_test_state_channel_permission_delete_message_error'
    },
    [TestChannelStateCode.ChannelPermissionEditMessageError]: {
        type: 'Text',
        text: 'channel_test_state_channel_permission_edit_message_error'
    },
    [TestChannelStateCode.ChannelPermissionAddUsersError]: {
        type: 'Text',
        text: 'channel_test_state_channel_permission_add_users_error'
    },
    [TestChannelStateCode.ConnectedGroupNotFoundError]: {
        type: 'Text',
        text: 'channel_test_state_connected_group_not_found_error'
    },
    [TestChannelStateCode.ConnectedGroupPermissionSendMessageError]: {
        type: 'Text',
        text: 'channel_test_state_connected_group_permission_send_message_error'
    },
    [TestChannelStateCode.ConnectedGroupPermissionPinMessageError]: {
        type: 'Text',
        text: 'channel_test_state_connected_group_permission_pin_message_error'
    },
    [TestChannelStateCode.ConnectedGroupPermissionDeleteMessageError]: {
        type: 'Text',
        text: 'channel_test_state_connected_group_permission_delete_message_error'
    },
    [TestChannelStateCode.ConnectedGroupPermissionEditMessageError]: {
        type: 'Text',
        text: 'channel_test_state_connected_group_permission_edit_message_error'
    },
    [TestChannelStateCode.ConnectedGroupBotNotAdmin]: {
        type: 'Text',
        text: 'channel_test_state_connected_group_bot_not_admin'
    }
}
