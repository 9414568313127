










































































































































import SelectActionModal from "@/components/Post/NewActions/components/PostAction/SelectPostActionModal.vue";
import { PostAction } from "@/components/Post/NewActions/components/PostAction/PostAction";
import { ActionExecuteType } from "@/includes/types/Post.types";
import PostActionDelayPreview from "@/components/Post/NewActions/components/components/PostActionDelay/PostActionDelayPreview.vue";
import SelectPostActionMixin from "@/components/Post/NewActions/components/mixins/SelectPostActionMixin";

import Icon from 'piramis-base-components/src/components/Icon/Icon.vue';
import HelpMessage from "piramis-base-components/src/components/HelpMessage/HelpMessage.vue";

import { cloneDeep } from "lodash";
import { plainToInstance } from "class-transformer";
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  data() {
    return {
      PostAction,
      ActionExecuteType
    }
  },
  methods: {
    cloneDeep
  },
  components: {
    PostActionDelayPreview,
    SelectActionModal,
    Icon,
    HelpMessage
  }
})
export default class SelectPostAction extends Mixins<SelectPostActionMixin<PostAction>>(SelectPostActionMixin) {
  rawActionsToInstance(actions: Array<any>): Array<PostAction> {
    return plainToInstance(PostAction, actions)
  }
}
