
















import PeriodSimplifierMixin from "piramis-base-components/src/components/PeriodSimplifier/PeriodSimplifierMixin";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";

import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';

@Component({})
export default class PostActionDelayPreview extends Mixins(PeriodSimplifierMixin) {
  @Prop() delay!: number

  @Watch('delay', { immediate: true })
  onDelayChange(value: number) {
    this.calculateCurrentUnit(this.unitOptions, value, this.unitOptions[0])
  }

  get unitOptions(): Array<number> {
    return [ StepUnit.Second, StepUnit.Minute, StepUnit.Hour, StepUnit.Day, StepUnit.Week, StepUnit.Month ]
      .map((unit: StepUnit) =>  unit / StepUnit.Second)
  }

  get unitLabelTranslation() {
    if (this.currentUnit) {
      return this.unitLabel(this.currentUnit, this.delay)
    }

    return ''
  }
}
