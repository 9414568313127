






































































import { InputSetups } from '@/mixins/input-setups'
import PeriodConditions from '@/components/Post/Period/PeriodConditions.vue'
import { Conditions, CreatedPost } from '@/includes/types/Post.types'
import { PostService } from '@/includes/services/PostService'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { StepUnitAlias, TimeUnitInputMode } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { PostType } from 'piramis-base-components/src/shared/modules/posting/types'

import Component from 'vue-class-component'
import { Mixins, Prop, PropSync, VModel, Watch } from 'vue-property-decorator'
import { debounce } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    PeriodConditions
  },
  data() {
    return {
      StepUnitAlias,
      TimeUnitInputMode,
      PostType
    }
  }
})
export default class PeriodSettings extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  @VModel() model !: CreatedPost

  @Prop({ type: Array }) initConditions !: Array<Conditions>

  @PropSync('disabled') isPeriodDisabled!: boolean

  @Prop({ type: Boolean, default: false }) disabledFields!: boolean

  @Watch('scheduleCondition')
  onScheduleConditionsChange() {
    if (this.model.schedule && this.model.schedule.period) {
      this.model.schedule.period.conditions = this.scheduleCondition
    }
  }

  @Watch('model', { deep:true })
  onModelChange(newModel:CreatedPost) {
    if (newModel.post.message.type === PostType.Post) {
      newModel.post.message.variants.forEach(editor => {
        editor.remove_previous = this.removePrevMessage
      })
    }
  }

  scheduleCondition: Array<Conditions> = []

  scheduleDates: Array<string> | null = null

  datesLoading = false

  scheduleConditionUnwatch: any

  scheduleRuntimeUnwatch: any

  scheduleIntervalUnwatch: any

  private removePrevious = false

  get removePrevMessage():boolean {
    return this.removePrevious
  }

  set removePrevMessage(switchState:boolean) {
    this.removePrevious = switchState

    if (this.model.post.message.type === PostType.Post) {
      this.model.post.message.variants.forEach(editor => {
        editor.remove_previous = switchState
      })

    }
  }

  get moreThen48Hours() {
    if (this.model && this.model.schedule.period && this.model.schedule.period.interval) {
      if (this.model.schedule.period?.unit === StepUnitAlias.HOURS) {
        return this.model.schedule.period.interval >= 48
      } else if (this.model.schedule.period?.unit === StepUnitAlias.DAYS) {
        return this.model.schedule.period.interval > 2
      } else if (this.model.schedule.period?.unit === StepUnitAlias.MINUTES) {
        return this.model.schedule.period.interval >= 2880
      } else if (this.model.schedule.period?.unit === StepUnitAlias.SECONDS) {
        return this.model.schedule.period.interval >= 172800
      } else {
        return true
      }
    }

    return false
  }

  get removePrevPostDisabled() {
    return this.disabledFields || this.moreThen48Hours
  }

  get scheduleDate(): string {
    return this.$t('repeat_post_dates_list', [ moment(this.model.schedule.run_time).locale('ru-RU').format('LLL') ]).toString()
  }

  onUnitChange(unit: StepUnitAlias) {
    if (this.model.schedule.period) {
      this.model.schedule.period.unit = unit

      if (this.model.schedule.period && this.model.schedule.period.interval && this.model.schedule.period.interval > 0) {
        this.getScheduleDates()
      }
    }
  }

  modelSetter(args: FieldData): FieldData {
    if (this.model.schedule && args.key === 'interval' && args.model === null) {
      this.model.schedule.period = {
        interval: 0,
        unit: 'MINUTES',
        conditions: []
      }
    }

    args.setter = (value: any): void => {
      this.$set(args.model, args.key as string, value)
    }
    return args
  }

  async getScheduleDates(): Promise<void> {
    try {
      this.datesLoading = true
      const response = await PostService.getScheduleDates('tg', {
        board_key: this.$store.state.boardsState.activeBoard!.board,
        schedule: this.model.schedule
      })
      if (response.run_time.length < 1) {
        this.scheduleDates = []
        this.isPeriodDisabled = true
      } else {
        this.scheduleDates = response.run_time.map((date) => moment(date).format('LLL'))
        this.isPeriodDisabled = false
      }
    } finally {
      this.datesLoading = false
    }
  }

  mounted() {
    if (this.model.post.message.type === PostType.Post) {
      this.removePrevMessage = this.model.post.message.variants.some(v => v.remove_previous)
    }

    this.scheduleConditionUnwatch = this.$watch('scheduleCondition', debounce(this.getScheduleDates, 1000), { deep: true })

    this.scheduleIntervalUnwatch = this.$watch('model.schedule.period.interval', debounce((newVal) => {
      if (newVal > 0) {
        this.getScheduleDates()
      }
    }, 1000), { immediate: true })

    this.scheduleRuntimeUnwatch = this.$watch('model.schedule.run_time', debounce(() => {
      if (this.model.schedule.period && this.model.schedule.period.interval && this.model.schedule.period.interval > 0) {
        this.getScheduleDates()
      }
    }, 1000))
  }

  beforeDestroy() {
    this.scheduleConditionUnwatch()
    this.scheduleRuntimeUnwatch()
    this.scheduleIntervalUnwatch()
  }
}
