var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"post-styles-setup"},[(_vm.styleCopy)?_c('div',[_c('div',{staticClass:"flex flex-col md:flex-row md:justify-between md:items-start gap-5"},[(_vm.isFieldVisible('post_price'))?_c('number-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.styleCopy.data,
            'key': 'post_price',
            'min': 0,
            'disabled': _vm.disabled
          }
        }}}):_vm._e(),(_vm.isFieldVisible('color'))?_c('post-color-select',{attrs:{"disabled":_vm.disabled},model:{value:(_vm.styleCopy.data.color),callback:function ($$v) {_vm.$set(_vm.styleCopy.data, "color", $$v)},expression:"styleCopy.data.color"}}):_vm._e()],1),(_vm.isFieldVisible('targets'))?_c('select-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy.data,
          'key': 'targets',
          'prefix': 'post_style_',
          'options': _vm.$store.getters.boardTargetOptions,
          'multiple': true,
          'selectAll': true
        },
      }}}):_vm._e(),(_vm.isFieldVisible('protect_content'))?_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'protect_content',
          'disabled': _vm.disabled
        }
      }}}):_vm._e(),(_vm.isFieldVisible('disable_notify'))?_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'disable_notify',
          'disabled': _vm.disabled
        }
      }}}):_vm._e(),(_vm.postType === null || _vm.postType === _vm.PostType.Post)?[(_vm.isFieldVisible('disable_link_preview'))?_c('switch-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.styleCopy,
            'key': 'disable_link_preview',
            'disabled': _vm.disabled
          }
        }}}):_vm._e()]:_vm._e(),(_vm.isFieldVisible('pin'))?_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'pin',
          'disabled': _vm.disabled
        }
      }}}):_vm._e()],2):_vm._e(),(_vm.isFieldVisible('post_comments_state'))?_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.styleCopy.data,
        'key': 'post_comments_state',
        'options': _vm.postCommentsStateOptions,
        'multiple': false,
        'disabled': _vm.disabled,
        'clearable': false,
      },
    }}}):_vm._e(),(_vm.isFieldVisible('compress_level'))?_c('select-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.styleCopy,
        'key': 'compress_level',
        'options': _vm.compressLevelOptions,
        'multiple': false,
        'disabled': _vm.disabled
      },
    }}}):_vm._e(),(_vm.postType === null || _vm.postType === _vm.PostType.Post)?[(_vm.isFieldVisible('prefix_message'))?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'hasMedia': false,
          'model': _vm.styleCopy,
          'key': 'prefix_message',
          'availableButtonsTypes': _vm.getNewDefaultButtons(),
          'base-api-url': 'api.presscode.app/cabinet',
          'targetToUpload': {
            'target': 'target',
            'target_id':  _vm.board
          },
          'buttonsHelpView': _vm.EditorButtonsHelpView,
          'availableTabs': [_vm.MessageEditorWithMediaTab.EditorTab, _vm.MessageEditorWithMediaTab.TextareaTab],
          'disabled': _vm.disabled
        }
      }}}):_vm._e(),(_vm.isFieldVisible('postfix_message'))?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.styleCopy,
          'key': 'postfix_message',
          'hasMedia': false,
          'availableButtonsTypes': _vm.getNewDefaultButtons(),
          'base-api-url': 'api.presscode.app/cabinet',
          'targetToUpload': {
            'target': 'target',
            'target_id': _vm.board
          },
          'buttonsHelpView': _vm.EditorButtonsHelpView,
          'availableTabs': [_vm.MessageEditorWithMediaTab.EditorTab, _vm.MessageEditorWithMediaTab.TextareaTab],
          'disabled': _vm.disabled
        }
      }}}):_vm._e(),(_vm.isFieldVisible('watermark_type'))?[_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.styleCopy,
            'key': 'watermark_type',
            'options': _vm.watermarkTypes,
            'multiple': false,
            'clearable': false,
            'disabled': _vm.disabled
          },
        }},on:{"input":_vm.onSelectChange}}),(_vm.styleCopy.watermark_type !== 'None' && _vm.styleCopy.watermark_type !== null )?_c('select-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.styleCopy,
            'key': 'watermark_position',
            'options': _vm.watermarkPosition,
            'multiple': false,
            'clearable': false,
            'disabled': _vm.disabled
          },
        }}}):_vm._e(),(_vm.styleCopy.watermark_type === 'Text')?_c('text-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.styleCopy,
            'key': 'watermark_data',
            'validation': 'required',
            'disabled': _vm.disabled
          },
        }}}):_vm._e(),(_vm.styleCopy.watermark_type === 'File')?_c('file-uploader-targets-input',{staticClass:"watermark_uploader",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.styleCopy,
            'key': 'watermark_data',
            'multiple': false,
            'base-api-url': 'api.presscode.app/cabinet',
            'available-mime-types': ['image/png', 'image/jpeg' ],
            'uploadType': _vm.FileType.Document,
            'targetToUpload': function () {
              return {
                'target': 'target',
                'target_id': _vm.board
              }
            },
            'disabled': _vm.disabled
          },
        }}}):_vm._e()]:_vm._e(),(_vm.isFieldVisible('actions') && _vm.showDelayedActions)?_c('accordion',{staticClass:"actions",attrs:{"type":"slim","close-title":_vm.$t('accordion_post_actions_title'),"open-title":_vm.$t('accordion_post_actions_title')},model:{value:(_vm.actionsAccordionOpen),callback:function ($$v) {_vm.actionsAccordionOpen=$$v},expression:"actionsAccordionOpen"}},[_c('select-post-action',{attrs:{"post-actions":_vm.styleCopy.actions,"action-types":_vm.postStylesActions,"disabled":_vm.disabled},on:{"update-actions":function($event){_vm.styleCopy.actions = $event}}})],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }