











import PostActionDelay from "@/components/Post/NewActions/components/components/PostActionDelay/PostActionDelay.vue";
import { PostAction } from "@/components/Post/NewActions/components/PostAction/PostAction";

import { Vue, Component, VModel } from 'vue-property-decorator';

@Component({
  components: {
    PostActionDelay
  }
})
export default class PostActionWrapper extends Vue {
  @VModel() delay!: PostAction['delay']
}
