import { render, staticRenderFns } from "./PostActionDelayPreview.vue?vue&type=template&id=0774e228&scoped=true&"
import script from "./PostActionDelayPreview.vue?vue&type=script&lang=ts&"
export * from "./PostActionDelayPreview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0774e228",
  null
  
)

export default component.exports