import { PostActionBuilder } from "@/components/Post/NewActions/components/ActionTypes/PostActionModel";
import { PostActionType } from "@/includes/types/Post.types";
import { PostMessage } from "../PostMessage";

import { PostType } from "piramis-base-components/src/shared/modules/posting/types";
import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { plainToInstance, Type } from "class-transformer";

export default class SendReplyMessageActionModel extends PostActionBuilder {
    type: PostActionType = PostActionType.SendReplyMessageAction;

    @Type(() => PostMessage)
    message: PostMessage = plainToInstance(PostMessage, {
        type: PostType.Post,
        variants: [ {
            'pin': false,
            'text': '',
            'buttons': [],
            'disable_notify': false,
            'remove_previous': false,
            'disable_link_preview': false,
            'attachments': [],
            'send_after': 0,
            'remove_after': 0,
            'protect_content': false
        } ]
    })

    icon: string = 'reply_all'
    view: VueComponent | undefined = () => import('./SendReplyMessageActionView.vue')
    valueView: VueComponent | undefined = () => import('../components/PostActionMessage/PostActionMessageValuePreview.vue')
}